/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     OUESTADAM CONFIDENTIAL                                  !
  !                                                             !
  !     WWW.OUESTADAM.COM Web App Code                          !
  !     (C) COPYRIGHT OUESTADAM 2024-2025                       !
  !     Licensed Internal Code - Property of OUESTADAM          !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : wwwConfig.js                                       !
  !  Desc. : www.ouestadam.com Configuration parameters         !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 31/01/2025                                         !
  !                                                             !
  !  0.1: Creation                                              !
  +-------------------------------------------------------------+
*/
/*=============== Definitions ==================================*/
/*
--- SissiF configuration parameters
*/
export const wwwConfig = {
    version: "1.03.05",
    modification: "31/01/2025",
    locale: "fr-FR",
    colors: {
        backgroundMainGrey: "#6F7170",
        backgroundMainBlack: "#000000",
        backgroundMainWhite: "#ffffff",
        backgroundCoordinateStart: "#77411f",
        backgroundCoordinateEnd: "#d89e5c",
        backgroundIcon: "#f3f2f0",
        foregroundMain: "#ffffff",
        foregroundIcon: "#ffffff",
        foregroundError: "#ff0000",
    },
    urlCV: "https://ouestadam.com",
    urlFreeware: "https://ouestadam.com/freeware",
    urlACEsteve: "https://acesteve.fr",
    urlCEsteve: "https://clemenceesteve.fr",
    urlDownloadOetaskreportWindows: "https://ouestadam.com/downloads/oetaskreport-1.2.4%20Setup.exe",
    urlDownloadOetaskreportDebian: "https://ouestadam.com/downloads/oetaskreport_1.2.4_amd64.deb",
    urlDownloadOetaskreportRedhat: "https://ouestadam.com/downloads/oetaskreport-1.2.4-1.x86_64.rpm",
    urlGithubOeTaskReport: "https://github.com/Ouestadam/OeTaskReport/",
    urlLicenses: "https://www.gnu.org/licenses/",
    versionOetaskreport : "1.2.4"
};